










































import { Component, Prop, Ref } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import { VForm } from "@/types/vForm";
import SnackbarModule from "@/store/snackbarModule";
import Validation from "@/mixins/validation";
import Api from "@/api";
import { PasswordResetModel } from "@/api/generated";
import environment from '@/environment';

const snackbarModule = getModule(SnackbarModule);

@Component
export default class PasswordReset extends Validation {
  @Ref() readonly form!: VForm;
  @Prop(String) private passwordResetToken!: string;

  private confirmPassword = "";
  private loading = false;
  private model: PasswordResetModel = {
    password: ""
  };

  get getPasswordsMatch() {
    return this.model.password == this.confirmPassword || "Password must match";
  }

  private async onResetPassword() {
    if (!this.form.validate()) {
      return;
    }

    try {
      this.loading = true;
      const response: any = await Api.AuthService.apiAuthResetPasswordIdPost(
        this.passwordResetToken,
        this.model
      );

      snackbarModule.setSnackbarMessage("Successfully reset password");

      const action = response.data.action;
      if(action === "REDIRECT_TO_MOBILE_APP_LOGIN") {
        window.location.replace(environment.APP_CLIENT_URL);
      } else {
        this.$router.replace({ name: "Login" });
      }
    }
    catch {
      snackbarModule.setSnackbarMessage("Failed to reset password");
    } finally {
      this.loading = false;
    }
  }
}
